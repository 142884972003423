@use 'styles/breakpoints' as *;

.container {
  display: grid;
  margin: auto;
  padding: 0 2rem;
  width: 100%;
  row-gap: 4rem;

  &.centered {
    text-align: center;
    justify-items: center;
  }

  &.left {
    justify-items: start;
  }

  &.cast {
    row-gap: 2.5rem;
  }

  @include desktop {
    padding-inline: 4.5rem;
    padding: 0;
    column-gap: 5rem;
    row-gap: 6rem;
    max-width: 80rem;

    &.narrow {
      padding: 0;
      max-width: 60rem;
    }

    &.cast {
      direction: rtl;
      text-align: left;
      grid-template-columns: repeat(2, 1fr);
    }

    &.tiles {
      grid-template-columns: repeat(3, 1fr);
      align-items: stretch;
    }

    &.illustration {
      grid-template-columns: repeat(3, 1fr);
    }

    &.default {
      max-width: 65rem;
    }

    &.wide {
      max-width: 80rem; //1280px
    }

    &.xWide {
      max-width: 105rem; //1680px
    }

    &.list {
      grid-template-columns: repeat(2, 1fr);
      align-items: stretch;
    }

    &.jobs,
    &.tiles {
      grid-template-columns: repeat(3, 1fr);
    }

    &.media {
      grid-template-columns: 2fr 3fr;
      align-items: center;
    }
  }
}
